<template>
  <v-row justify="center">
    <v-dialog v-model="dialogProps.dialog" :max-width="dialogProps.width">
      <v-card>
        <v-card-title class="headline">
          <slot name="card-title"></slot>
        </v-card-title>
        <v-card-text>
          <v-container>
            <slot name="card-body"></slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="card-action"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "custom-dialog",
  props: ["dialogProps"],
  data() {
    return {};
  }
};
</script>