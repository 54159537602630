<template>
  <div id="teams">
    <v-container fluid>
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>

      <!-- table -->
      <customTable
        :tableProps="tableProps"
        @add="add_role"
        @view="view_role"
        @edit="edit_role"
        @delete="delete_role"
        class="ctable"
      ></customTable>
      <!-- dialog -->
      <customDialog :dialogProps="dialogProps">
        <!-- title card -->
        <span slot="card-title" class="captalize">{{dialogType}} team</span>
        <!-- card body -->
        <span slot="card-body">
          <!-- form for add or edit or delete -->
          <v-form
            ref="form"
            v-model="valid"
            autocomplete="off"
            v-show="dialogType != 'view' && dialogType != 'delete'"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field label="team name" v-model="form.name" :rules="rules.name" dense></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <!-- view/delete table -->
          <table class="view-table" v-show="dialogType == 'view' || dialogType == 'delete'">
            <span
              v-show="dialogType == 'delete'"
              class="warning-msg"
            >Are you sure, you want delete !</span>
            <tr v-for="(value, key) in view_item" :key="key">
              <th v-show="key != 'id'" class="captalize">{{key}}</th>
              <td v-show="key != 'id'">:</td>
              <td v-show="key != 'id'" class="captalize">{{value}}</td>
            </tr>
          </table>
        </span>
        <!-- card action buttons -->
        <span slot="card-action">
          <v-btn text @click="dialogProps.dialog = false">Close</v-btn>
          <v-btn
            v-show="dialogType != 'view'"
            type="submit"
            :loading="form_loading"
            :disabled="form_loading"
            :color="(dialogType == 'delete') ? 'error' : 'primary'"
            @click="save_form"
          >{{ (dialogType == 'edit') ? 'Update' : dialogType }}</v-btn>
        </span>
      </customDialog>
    </v-container>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import customTable from "@/components/custom-table";
import customDialog from "@/components/custom-dialog";

export default {
  name: "teams",
  mixins: [Mixins],
  components: {
    customTable,
    customDialog
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "User Management",
          disabled: false,
          to: { name: "user-management" },
          exact: true
        },
        {
          text: "teams",
          disabled: true,
          href: ""
        }
      ],
      // table props
      tableProps: {
        loading: false,
        tableHeader: "teams",
        headerButton: true,
        headerButtonLabel: "team",
        index: true,
        action_button_view: true,
        action_button_edit: true,
        action_button_delete: true,
        headers: [
          { text: "S.No", align: "left", value: "index", sortable: false },
          { text: "Name", align: "left", value: "name" },
          { text: "Action", align: "center", value: "action", sortable: false }
        ],
        items: []
      },
      // dialog props
      dialogProps: {
        dialog: false,
        width: "600px"
      },

      // dialog type -> add, edit, delet or view
      dialogType: "",

      // if view/delete action -> temp data store in this variable
      view_item: {},

      // form
      form_loading: false,
      valid: true,
      form: {
        name: ""
      },
      // rules
      rules: {
        name: [this.required("team name")]
      }
    };
  },
  methods: {
    // open add dialog
    add_role() {
      this.dialogType = "add";
      this.dialogProps.dialog = true;
      this.$refs.form.reset();
    },
    // open view dialog
    view_role(data) {
      this.view_item = data;
      this.dialogType = "view";
      this.dialogProps.dialog = true;
    },
    // open edit dialog
    edit_role(data) {
      this.form = data;
      this.dialogType = "edit";
      this.dialogProps.dialog = true;
    },
    // open delete dialog
    delete_role(data) {
      this.view_item = data;
      this.form = data;
      this.dialogProps.dialog = true;
      this.dialogType = "delete";
    },

    // submit form (add, edit, delete)
    async save_form() {
      // validate form
      if (!this.$refs.form.validate())
        return this.alert("warning", "", "Invalid form");

      // set loader
      this.form_loading = true;

      let response;

      // add service
      if (this.dialogType == "add")
        response = await this.addData("user/team-create", this.form);

      // edit service
      if (this.dialogType == "edit")
        response = await this.updateData(
          `user/team-update/${this.form.id}`,
          this.form
        );

      // delete service
      if (this.dialogType == "delete")
        response = await this.deleteData(`user/team-delete/${this.form.id}`);

      // unset loader
      this.form_loading = false;

      // alert the user
      if (response.status == 200)
        this.tableProps.items = await this.getData("user/team-list");
      this.alert("success", "", "successfully submitted");

      if (response.status != 200) this.alert("error", "", "Error in submitted");

      // reset the form after done (uncomment after wired up)
      this.$refs.form.reset();
      this.dialogProps.dialog = false;
    }
  },
  async mounted() {
    this.tableProps.items = await this.getData("user/team-list");
  }
};
</script>

<style scoped>
.ctable {
  width: 80%;
  margin: auto;
  margin-top: 3%;
}
</style>