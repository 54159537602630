<template>
  <div id="custom-table">
    <!-- table -->
    <v-data-table
      :headers="tableProps.headers"
      :items="tableProps.items"
      :loading="tableProps.loading"
      loading-text="Loading... Please wait"
      :items-per-page="5"
      :search="search"
      class="elevation-1"
    >
      <!-- table header -->
      <template v-slot:top v-if="tableProps.tableHeader">
        <v-toolbar flat color="white">
          <v-toolbar-title class="captalize">
            {{tableProps.tableHeader}}
            <v-chip v-show="tableProps.headerChip" class="ma-2" color="green" small text-color="white">
              {{tableProps.headerChip}}
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer v-if="tableProps.headerList"></v-spacer>
          <v-autocomplete
            v-if="tableProps.headerList"
            style="margin: 35px 0 0 0"
            v-model="search"
            clearable
            class="mb-2"
            :label="tableProps.headerListLabel"
            :items="tableProps.headerList"
          ></v-autocomplete>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-if="tableProps.headerButton"
            @click="$emit('add')"
          >
            <v-icon left>mdi-plus</v-icon>
            {{tableProps.headerButtonLabel}}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- table index -->
      <template
        v-if="tableProps.index"
        v-slot:item.index="{ item }"
      >{{tableProps.items.map(function(x) {return x; }).indexOf(item) + 1}}</template>

      <!-- table action -->
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="tableProps.action_button_view"
          text
          icon
          class="mr-2"
          @click="$emit('view', item)"
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="tableProps.action_button_edit"
          text
          icon
          class="mr-2"
          color="warning"
          @click="$emit('edit', item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="tableProps.action_button_delete"
          text
          icon
          class="mr-2"
          color="error"
          @click="$emit('delete', item)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "custom-table",
  props: ["tableProps"],
  data() {
    return {
      search: ""
    };
  }
};
</script>